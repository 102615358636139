import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../core/domain/usecases/setServerSideUseCase";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import {
  IListPecegePayAccountReceivableFeesContract,
  IListPecegePayAccountReceivableFeesParams,
} from "../../domain/contracts/listPecegePayAccountReceivableFeesContract";
import { IPecegePayAccountReceivableFeeEntity } from "../../domain/entities/pecegePayAccountReceivableFeeEntity";

export class ListPecegePayAccountReceivableFeesService
  implements IListPecegePayAccountReceivableFeesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
    private setServerSideService: ISetServerSideService,
  ) {}

  async listPecegePayAccountReceivableFees({
    payload,
    showActivesOnly,
  }: IListPecegePayAccountReceivableFeesParams) {
    const userEntity = this.getUserLocalService.get();
    let url = "/PecegePayAccountReceivableFees";

    if (showActivesOnly) {
      url += "/Actives";
    }
    const serverSideId = await this.setServerSideService.setServerSide(payload);

    const params = new URLSearchParams({
      body: serverSideId,
    });

    return this.api.get<
      IServerSideResponseModel<IPecegePayAccountReceivableFeeEntity[]>
    >(url, {
      params,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
