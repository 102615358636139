import {
  MenuItem,
  MenuItemCommandParams,
  MenuItemOptions,
} from "primereact/menuitem";
import { useMemo } from "react";
import {
  FaArrowAltCircleLeft,
  FaBug,
  FaCheckCircle,
  FaDatabase,
  FaEye,
  FaFileInvoiceDollar,
  FaPaperclip,
  FaRegClone,
  FaStream,
  FaTimesCircle,
  FaUndo,
} from "react-icons/fa";
import { SoulRoutes } from "../../../../../admin/domain/entities/soulRoutes";
import { EUserProfile } from "../../../../../core/domain/entities/userEntity";
import { useUserLocal } from "../../../../../core/presentation/hooks/useUserLocal";
import {
  EAccountPayableStatus,
  EFuspSendingStatus,
  EReturnStatus,
  IAccountPayableListItemEntity,
} from "../../domain/entities/accountPayableListItemEntity";
import { ContextMenuItem } from "../components/AccountsPayablePageContextMenu/styles";
import { useAccountsPayablePage } from "./useAccountsPayablePage";

export function useAccountsPayableContextMenu() {
  const {
    user: { profile },
  } = useUserLocal();

  const {
    menuRef,
    handleRemoveTerminationItemClick,
    handleCancelItemClick,
    handleReturnItemClick,
    handleUndoReturnItemClick,
    handleAssessmentsItemClick,
    handleAttachmentItemClick,
    handleTerminateItemClick,
    handleFeeItemClick,
    handleFuspSendingFailureReasonButtonClick,
    ...rest
  } = useAccountsPayablePage();

  const { contextMenuData } = rest.state;

  return useMemo(() => {
    const id = contextMenuData?.id || "";
    const status = contextMenuData?.status;
    const isTax = contextMenuData?.isTax ?? false;
    const returnStatus = contextMenuData?.returnStatus;
    const isTaxRetention = contextMenuData?.isFeeRetention ?? false;
    const retainTaxes = contextMenuData?.providerRetainFee ?? false;
    const canSeeFeesButton = profile !== EUserProfile.internal;
    const isPecegePayFee = Boolean(contextMenuData?.pecegePayFeeId);

    /**
     * Botão "Editar".
     * Visível quando a conta não se tratar de uma taxa e não tenha
     * sido devolvida.
     */
    const editButton: MenuItem = {
      label: "Editar",
      target: "_blank",
      data: contextMenuData,
      url: `${SoulRoutes.ACCOUNTS_PAYABLE.path}/${id}`,
      visible: !isTax && returnStatus === EReturnStatus.NotReturned,
      icon: (
        <FaDatabase
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-color02)",
          }}
        />
      ),
    };

    /**
     * Botão "Visualizar".
     * Visível quando a conta se tratar de alguma taxa ou caso ainda não tenha
     * sido devolvida.
     */
    const viewButton: MenuItem = {
      label: "Ver",
      data: contextMenuData,
      url: `${SoulRoutes.ACCOUNTS_PAYABLE.path}/${id}`,
      visible: isTax || returnStatus !== EReturnStatus.NotReturned,
      icon: (
        <FaEye
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-color02)",
          }}
        />
      ),
    };

    /**
     * Botão "Baixar".
     * Sempre visível.
     */
    const terminateButton: MenuItem = {
      data: contextMenuData,
      disabled:
        isTax ||
        status === EAccountPayableStatus.Paid ||
        status === EAccountPayableStatus.Canceled ||
        returnStatus !== EReturnStatus.NotReturned,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link terminate ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Baixar">
              <FaCheckCircle />
              Baixar
            </span>
          </ContextMenuItem>
        );
      },
      command(event) {
        const accountPayable = event?.item
          ?.data as IAccountPayableListItemEntity;

        if (!accountPayable) {
          return;
        }

        handleTerminateItemClick(accountPayable);
      },
    };

    /**
     * Botão "Retirar baixar".
     * Sempre visível para perfis diferentes de "interno".
     */
    const removeTerminationButton: MenuItem = {
      data: contextMenuData,
      visible: profile !== EUserProfile.internal,
      disabled:
        isTax ||
        status === EAccountPayableStatus.Open ||
        status === EAccountPayableStatus.Canceled ||
        returnStatus !== EReturnStatus.NotReturned,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link remove-termination ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Retirar baixa">
              <FaArrowAltCircleLeft />
              Retirar baixa
            </span>
          </ContextMenuItem>
        );
      },
      command(event: MenuItemCommandParams) {
        const accountPayable = event?.item
          ?.data as IAccountPayableListItemEntity;

        if (!accountPayable) {
          return;
        }

        handleRemoveTerminationItemClick(accountPayable);
      },
    };

    /**
     * Botão "Devolver".
     * Visível caso a conta não tenha sido retornada e ela já tenha sido baixada.
     * Somente para perfis diferentes de "interno"
     */
    const returnButton: MenuItem = {
      data: contextMenuData,
      disabled:
        isPecegePayFee || isTax || returnStatus !== EReturnStatus.NotReturned,
      visible:
        profile !== EUserProfile.internal &&
        returnStatus === EReturnStatus.NotReturned &&
        status === EAccountPayableStatus.Paid,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Devolução">
              <FaUndo />
              Devolução
            </span>
          </ContextMenuItem>
        );
      },
      command(event: MenuItemCommandParams) {
        const accountPayable = event?.item
          ?.data as IAccountPayableListItemEntity;

        if (!accountPayable) {
          return;
        }

        handleReturnItemClick(accountPayable);
      },
    };

    /**
     * Botão "Desfazer devolução".
     * Visível apenas caso a conta não seja a respeito de uma taxa, além de não
     * ter sido devolvida.
     * Somente para perfis diferentes de "interno"
     */
    const undoReturnButton: MenuItem = {
      data: contextMenuData,
      // sempre que presente está habilitado
      disabled: false,
      visible:
        profile !== EUserProfile.internal &&
        !isTax &&
        !isPecegePayFee &&
        returnStatus !== EReturnStatus.NotReturned,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Desfazer Devolução">
              <FaUndo />
              Desfazer Devolução
            </span>
          </ContextMenuItem>
        );
      },
      command(event: MenuItemCommandParams) {
        const accountPayable = event?.item
          ?.data as IAccountPayableListItemEntity;

        if (!accountPayable) {
          return;
        }

        handleUndoReturnItemClick(accountPayable);
      },
    };

    /**
     * Botão "Cancelar".
     * Sempre visível.
     */
    const cancelButton: MenuItem = {
      data: contextMenuData,
      disabled:
        isTax ||
        isPecegePayFee ||
        status === EAccountPayableStatus.Paid ||
        returnStatus !== EReturnStatus.NotReturned ||
        status === EAccountPayableStatus.Canceled,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link cancel ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Cancelar">
              <FaTimesCircle />
              Cancelar
            </span>
          </ContextMenuItem>
        );
      },
      command(event: MenuItemCommandParams) {
        const accountPayable = event?.item
          ?.data as IAccountPayableListItemEntity;

        if (!accountPayable) {
          return;
        }

        handleCancelItemClick(accountPayable);
      },
    };

    /**
     * Botão "Alterar anexos".
     * Sempre visível.
     */
    const changeAttachmentsButton: MenuItem = {
      data: contextMenuData,
      disabled: isTax || status === EAccountPayableStatus.Canceled,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Alterar anexos">
              <FaPaperclip />
              Alterar anexos
            </span>
          </ContextMenuItem>
        );
      },
      command(event) {
        const accountPayable = event?.item
          ?.data as IAccountPayableListItemEntity;

        if (!accountPayable) {
          return;
        }

        handleAttachmentItemClick(accountPayable);
      },
    };

    /**
     * Botão "Impostos".
     * Visível apenas se o fornecedor da conta retém impostos e a conta não
     * for uma retenção de impostos.
     */
    const taxesButton: MenuItem = {
      data: contextMenuData,
      disabled: status === EAccountPayableStatus.Canceled,
      visible: canSeeFeesButton && retainTaxes && !isTaxRetention,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Impostos">
              <FaFileInvoiceDollar />
              Impostos
            </span>
          </ContextMenuItem>
        );
      },
      command(event) {
        const accountPayable = event?.item
          ?.data as IAccountPayableListItemEntity;

        if (!accountPayable) {
          return;
        }

        handleFeeItemClick(accountPayable);
      },
    };

    /**
     * Botão "Rateios".
     * Sempre visível.
     */
    const assessmentButton: MenuItem = {
      data: contextMenuData,
      disabled:
        isTax ||
        status === EAccountPayableStatus.Canceled ||
        returnStatus !== EReturnStatus.NotReturned,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Rateios">
              <FaStream />
              Rateios
            </span>
          </ContextMenuItem>
        );
      },
      command(event: MenuItemCommandParams) {
        const accountPayable = event?.item
          ?.data as IAccountPayableListItemEntity;

        if (!accountPayable) {
          return;
        }

        handleAssessmentsItemClick(accountPayable);
      },
    };

    /**
     * Botão "Duplicar".
     * Sempre visível.
     */
    const duplicateButton: MenuItem = {
      label: "Duplicar",
      target: "_blank",
      data: contextMenuData,
      disabled: isTax || false,
      url: `${SoulRoutes.ACCOUNTS_PAYABLE.path}/new?duplicate=${id}`,
      icon: (
        <FaRegClone
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-color02)",
          }}
        />
      ),
    };

    const fuspSendingFailureReasonButton: MenuItem = {
      label: "Motivo da falha FUSP",
      data: contextMenuData,
      visible:
        contextMenuData?.fuspSendingStatus ===
        EFuspSendingStatus.SendingFailure,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Desfazer Devolução">
              <FaBug />
              Motivo da falha FUSP
            </span>
          </ContextMenuItem>
        );
      },
      command(commandParams: MenuItemCommandParams) {
        const accountPayable = commandParams?.item
          ?.data as IAccountPayableListItemEntity;

        if (!accountPayable) {
          return;
        }

        handleFuspSendingFailureReasonButtonClick(accountPayable);
      },
    };

    const separator = { separator: true };

    const menuModel = [
      editButton,
      viewButton,
      separator,
      terminateButton,
      removeTerminationButton,
      returnButton,
      undoReturnButton,
      cancelButton,
      separator,
      fuspSendingFailureReasonButton,
      changeAttachmentsButton,
      taxesButton,
      assessmentButton,
      duplicateButton,
    ];

    return {
      menuModel,
      menuRef,
    };
  }, [
    contextMenuData,
    profile,
    menuRef,
    handleTerminateItemClick,
    handleRemoveTerminationItemClick,
    handleReturnItemClick,
    handleUndoReturnItemClick,
    handleCancelItemClick,
    handleAttachmentItemClick,
    handleFeeItemClick,
    handleAssessmentsItemClick,
    handleFuspSendingFailureReasonButtonClick,
  ]);
}
