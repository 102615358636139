import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { FaCaretDown, FaFileExcel, FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../core/data/services/apiService";
import { ViaCepApiService } from "../../../../core/data/services/viaCepApiService";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { SoulDropdown } from "../../../../core/presentation/components/SoulDropdown";
import { Toggle } from "../../../../core/presentation/components/Toggle";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import { ExportingModalContent } from "../../../../costCenter/presentation/components/ExportingModalContent";
import {
  MakeProvider,
  makeProvider,
} from "../../../../provider/main/makeProvider";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ISimpleTableHandle,
  SimpleTable,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import { IPecegePayAccountReceivableFeeEntity } from "../../../domain/entities/pecegePayAccountReceivableFeeEntity";
import {
  MakePecegePayAccountReceivableFees,
  makePecegePayAccountReceivableFees,
} from "../../../main/makePecegePayAccountReceivableFees";
import { usePecegePayReceivableFeesGrid } from "../../hooks/usePecegePayAccountReceivableFeesGrid";
import { ManagePecegePayAccountReceivableFeesModal } from "../ManagePecegePayAccountReceivableFeesModal";
import { PecegePayAccountReceivableFeeFormModal } from "../PecegePayReceivableFeesFormModal";
import { Container, ToolbarContainer } from "./styles";

export enum PecegePayAccountReceivableFeesPageModalType {
  None,
  Crud,
  Manage,
}

interface PecegePayReceivableFeesPageState {
  search: string;
  isLoading: boolean;
  globalFilter: string;
  showActivesOnly: boolean;
  pfsEvent: IPFSEventEntity;
  currentEntity?: IPecegePayAccountReceivableFeeEntity;
  currentModal: PecegePayAccountReceivableFeesPageModalType;
  data?: IServerSideResponseModel<IPecegePayAccountReceivableFeeEntity[]>;
}

interface PecegePayAccountReceivableFeesPageProps {
  useProvider: MakeProvider;
  usePecegePayAccountReceivableFees: MakePecegePayAccountReceivableFees;
}

export function PecegePayReceivableFeesPage(
  props: PecegePayAccountReceivableFeesPageProps,
) {
  const { usePecegePayAccountReceivableFees, useProvider } = props;

  const {
    listPecegePayAccountReceivableFees,
    togglePecegePayAccountReceivableFee,
    exportPecegePayAccountReceivableFees,
    getPecegePayAccountReceivableFeeTaxes,
  } = usePecegePayAccountReceivableFees;

  const dialog = useSoulDialog();
  const debounceTime = useDebounceTime();
  const { generatePayload } = useTables();
  const allowedProfiles = useAllowedProfiles();

  const canAdd = useMemo(
    () =>
      allowedProfiles(
        EUserProfile.supervisor,
        EUserProfile.financialManagement,
      ),
    [allowedProfiles],
  );

  const table = useRef<ISimpleTableHandle>(null);

  const [state, setState] = useState<PecegePayReceivableFeesPageState>({
    search: "",
    isLoading: false,
    globalFilter: "",
    showActivesOnly: false,
    pfsEvent: new PFSEventEntity(),
    currentModal: PecegePayAccountReceivableFeesPageModalType.None,
  });

  const handleTooglePecegePayReceivableFee = useCallback(
    async (payload: IPecegePayAccountReceivableFeeEntity) => {
      const action = payload.active ? "inativada" : "ativada";

      const result = await dialog.fire({
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Não",
        title: "Você está certo disso?",
        html: `A Tarifa será ${action}. <br /> Deseja prosseguir?`,
        preConfirm() {
          dialog.showLoading();
          return togglePecegePayAccountReceivableFee(payload.id);
        },
      });

      if (result.isConfirmed) {
        dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `Tarifa ${action} com sucesso!`,
        });

        table.current?.reload();

        return !payload.active;
      }

      return payload.active;
    },
    [dialog, togglePecegePayAccountReceivableFee],
  );

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState(prevState => ({
      ...prevState,
      search: e.target.value,
    }));

    debounceTime(() => {
      setState(prevState => ({
        ...prevState,
        globalFilter: e.target.value,
      }));
    }, 700);
  };

  const handleOpenModal = useCallback(
    (
      info: IPecegePayAccountReceivableFeeEntity,
      type: PecegePayAccountReceivableFeesPageModalType,
    ) => {
      setState(prevState => ({
        ...prevState,
        currentModal: type,
        currentEntity: info,
      }));
    },
    [],
  );

  const handleCloseCurrentModal = useCallback(() => {
    if (
      state.currentModal === PecegePayAccountReceivableFeesPageModalType.Crud
    ) {
      table.current?.reload();
    }

    setState(prevState => ({
      ...prevState,
      currentEntity: undefined,
      currentModal: PecegePayAccountReceivableFeesPageModalType.None,
    }));
  }, [state.currentModal]);

  const { columns } = usePecegePayReceivableFeesGrid({
    handleOpenModal,
    handleTooglePecegePayReceivableFee,
  });

  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      setState(prevState => ({
        ...prevState,
        isLoading: true,
        pfsEvent: _pfsEvent,
      }));

      try {
        const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
        const response = await listPecegePayAccountReceivableFees({
          payload,
          showActivesOnly: state.showActivesOnly,
        });

        setState(prevState => ({
          ...prevState,
          data: response,
        }));
      } catch (err) {
        return;
      } finally {
        setState(prevState => ({
          ...prevState,
          isLoading: false,
        }));
        ReactTooltip.rebuild();
      }
    },
    [
      columns,
      generatePayload,
      state.showActivesOnly,
      listPecegePayAccountReceivableFees,
    ],
  );

  const handleShowActivesOnly = async (value: boolean) => {
    setState(prevState => ({
      ...prevState,
      showActivesOnly: value,
    }));

    return value;
  };

  const handleExportSheet = async () => {
    dialog.fire({
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      html: <ExportingModalContent />,
    });

    const payload = generatePayload(state.pfsEvent, [] as ISimpleColumn[]);

    try {
      await exportPecegePayAccountReceivableFees({
        payload,
        showActivesOnly: state.showActivesOnly,
      });
    } finally {
      dialog.close();
    }
  };

  return (
    <Container>
      <ToolbarContainer>
        <InputSearch
          id="txt-search"
          value={state.search}
          data-testid="txt-search"
          onChange={handleSearchChange}
        />
        <label>
          <Toggle
            id="btn-toggle-invalid"
            value={state.showActivesOnly}
            data-testid="btn-toggle-invalid"
            onChange={handleShowActivesOnly}
          />
          Exibir somente ativos
        </label>
        {/*
        TODO - O back ainda não desenvolveu o endpoint de exportação
        excel. Como é uma demanda solicitada, embora não esteja presente
        na US, o front foi desenvolvido e segue aguardando o PR.
        <SoulDropdown
          toggler={
            <button
              type="button"
              id="btn-options"
              data-testid="btn-options"
              className="default-button options-context"
            >
              <span>Opções</span>
              <FaCaretDown className="context-dropdown-icon" />
            </button>
          }
        >
          <button
            type="button"
            className="dropdown-item"
            onClick={handleExportSheet}
          >
            <FaFileExcel />
            <span title="Exportar">Exportar para excel</span>
          </button>
        </SoulDropdown> */}
        {canAdd ? (
          <button
            id="btn-add"
            type="button"
            data-testid="btn-add"
            className="default-button"
            onClick={() => {
              setState(prevState => ({
                ...prevState,
                currentModal: PecegePayAccountReceivableFeesPageModalType.Crud,
              }));
            }}
          >
            <FaPlus /> Adicionar Tarifa
          </button>
        ) : null}
      </ToolbarContainer>
      <PecegePayAccountReceivableFeeFormModal
        useProvider={useProvider}
        feeId={state.currentEntity?.id || ""}
        onClose={handleCloseCurrentModal}
        usePecegePayAccountReceivableFees={usePecegePayAccountReceivableFees}
        isOpen={
          state.currentModal ===
          PecegePayAccountReceivableFeesPageModalType.Crud
        }
      />
      <ManagePecegePayAccountReceivableFeesModal
        feeId={state.currentEntity?.id || ""}
        onClose={handleCloseCurrentModal}
        getPecegePayAccountReceivableFeeTaxes={
          getPecegePayAccountReceivableFeeTaxes
        }
        isOpen={
          state.currentModal ===
          PecegePayAccountReceivableFeesPageModalType.Manage
        }
      />
      <SimpleTable
        tableRef={table}
        columns={columns}
        data={state.data}
        getList={getList}
        loading={state.isLoading}
        globalFilter={state.globalFilter}
      />
    </Container>
  );
}

interface PecegePayAccountsReceivablePageFactoryProps {
  api: IApiService;
}

export function PecegePayAccountReceivablePageFactory({
  api,
}: PecegePayAccountsReceivablePageFactoryProps) {
  const viaCepApi = new ViaCepApiService();

  const usePecegePayAccountReceivableFees =
    makePecegePayAccountReceivableFees(api);

  return (
    <PecegePayReceivableFeesPage
      useProvider={makeProvider(api, viaCepApi)}
      usePecegePayAccountReceivableFees={usePecegePayAccountReceivableFees}
    />
  );
}
