import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ITogglePecegePayAccountReceivableFeeContract } from "../../domain/contracts/togglePecegePayAccountReceivableFeeContract";
import { IUpdatePecegePayAccountReceivableFeeParams } from "../../domain/contracts/updatePecegePayAccountReceivableFeeContract";
import { IPecegePayAccountReceivableFeeEntity } from "../../domain/entities/pecegePayAccountReceivableFeeEntity";

export class TogglePecegePayAccountReceivableFeeService
  implements ITogglePecegePayAccountReceivableFeeContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  togglePecegePayAccountReceivableFee(feeId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/PecegePayAccountReceivableFees/${feeId}`;

    return this.api.delete<IPecegePayAccountReceivableFeeEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
